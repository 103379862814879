@font-face {
  font-family: Inter-Black;
  src: url(../fonts/inter/Inter-Black.ttf);
}

@font-face {
  font-family: Inter-Bold;
  src: url(../fonts/inter/Inter-Bold.ttf);
}

@font-face {
  font-family: Inter-ExtraBold;
  src: url(../fonts/inter/Inter-ExtraBold.ttf);
}

@font-face {
  font-family: Inter-ExtraLight;
  src: url(../fonts/inter/Inter-ExtraLight.ttf);
}

@font-face {
  font-family: Inter-Light;
  src: url(../fonts/inter/Inter-Light.ttf);
}

@font-face {
  font-family: Inter-Medium;
  src: url(../fonts/inter/Inter-Medium.ttf);
}

@font-face {
  font-family: Inter-Regular;
  src: url(../fonts/inter/Inter-Regular.ttf);
}

@font-face {
  font-family: Inter-SemiBold;
  src: url(../fonts/inter/Inter-SemiBold.ttf);
}

@font-face {
  font-family: Inter-Thin;
  src: url(../fonts/inter/Inter-Thin.ttf);
}

/* Body */

a:hover {
  text-decoration: none;
}

body {
  margin: 0;
  min-height: 100vh;
  display: flex;
  flex-flow: column;
  font-family: Inter-Regular;
  color: #161d48;
}

html,
body,
#app,
#root,
#app > div,
.App,
.view {
  margin: 0;
  min-height: 100vh;
  display: flex;
  flex-flow: column;
  font-family: Inter-Regular;
  background-color: #fbfcfe;
  color: #161d48;
}

/* Authentication */

.authentication-container {
  width: 576px;
  background-color: #fff;

  border: 0.8px solid #e4ebf6;
  filter: drop-shadow(0px 0px 20px rgba(223, 229, 243, 0.5));
  border-radius: 20px;

  padding-top: 52px;
  padding-bottom: 52px;
  padding-left: 76px;
  padding-right: 76px;
}

.authentication-container-logo {
  width: 60px;
  height: 60px;
}
@media (max-width: 960px) {
  .authentication-container {
    padding: 26px 30px;
  }
}

.authentication-disclaimer {
  font-family: Inter-Regular;
  font-weight: 400;
  font-size: 16px;

  letter-spacing: -0.01em;

  padding-top: 24px;
}

/* Input field */

.input-field-background {
  display: block;
  height: 64px;
  width: 100%;
  background: #f7f8fc;
  margin-bottom: 24px;

  border: 1px solid #e4ebf6;
  border-radius: 14px;
}

.error-field {
  border: 2px solid #f36b75;
}

.input-field {
  margin-top: 5px;
  height: 50px;
  width: calc(100% - 44px);
  margin-left: 24px;
  margin-right: 24px;
  border: 0px;

  font-family: Inter-Medium;
  font-style: normal;
  font-weight: 400;
  font-size: 17px;

  background-color: transparent;
}

.input-field::placeholder {
  color: #949faf;

  font-family: Inter-Regular;
  font-style: normal;
  font-weight: 500;
}

/* Simple button  */

.simple-button {
  background-color: transparent;
  border: 0px;

  font-family: Inter-SemiBold;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;

  color: #5064ff;

  margin: 0px;
  padding: 0px;

  text-align: left;
}

/* Action button */

.action-button-container-full {
  display: inline-block;
  width: 100%;
  height: 60px;
  background: #5064ff;
  border-radius: 12px;
}

.small-action-button-container-full {
  display: inline-block;
  width: 100%;
  height: 44px;

  background: #5064ff;
  border-radius: 12px;
}

.action-button {
  width: 100%;
  height: 100%;
  background: transparent;
  border: 0px;

  font-family: Inter-Medium;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;

  color: #ffffff;
}

.small-action-button {
  width: 100%;
  height: 100%;
  background: transparent;
  border: 0px;

  font-family: Inter-Medium;
  font-style: normal;
  font-weight: 200;
  font-size: 16px;

  padding-left: 20px;
  padding-right: 20px;

  color: #ffffff;
}

.slim-action-button {
  font-size: 18px;
}

/* Destroy button */

.destroy-button-container-full {
  display: inline-block;
  width: 100%;
  height: 60px;
  border: 2px solid #ffa5c0;
  background: #fff8f9;
  border-radius: 12px;
}

.small-destroy-button-container-full {
  display: inline-block;
  width: 100%;
  height: 44px;
  border: 2px solid #ffa5c0;
  background: #fff8f9;
  border-radius: 12px;
}

.destroy-button {
  width: 100%;
  height: 100%;
  background: transparent;
  border: 0px;

  font-family: Inter-Regular;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;

  color: #ff6f78;
}

.small-destroy-button {
  width: 100%;
  height: 100%;
  background: transparent;
  border: 0px;

  font-family: Inter-Medium;
  font-style: normal;
  font-weight: 200;
  font-size: 16px;

  padding-left: 20px;
  padding-right: 20px;

  color: #ff6f78;
}

.slim-destroy-button {
  font-size: 18px;
}

/* Error label */

.error-image {
  margin-bottom: 2px;
}

.error-text {
  margin-left: 5px;
  font-family: Inter-Regular;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 130%;
  letter-spacing: -0.01em;

  color: #f36b75;
}

/* Side bar */

.sidebar {
  width: 250px;
  height: 100%;

  background-color: white;
  float: left;
  position: fixed;
  border-right: 1px solid #e4ebf6;
}

.sidebar-content-top {
  margin-top: 40px;
  margin-left: 20px;
  margin-right: 20px;
  text-align: left;
}

/* Dropdown */

.add-new-project-container {
  background: #f7f8fc;
  border: 1px solid #e4ebf7;
  border-radius: 12px;

  height: 44px;

  display: flex;
  margin-top: 10px;
  margin-bottom: 10px;
  flex-direction: row;

  width: 100%;
}

.add-new-project-container .image {
  width: 10px;
  margin-left: 10px;
  float: left;
}

.close-item-add-project {
  margin-top: 10px;
}

.add-new-project-container .text {
  /* width: 50px; */
  margin-left: 32px;
  margin-top: 2px;

  width: 111px;
  height: 24px;

  font-family: Inter-SemiBold;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  /* identical to box height, or 171% */

  color: rgba(80, 100, 255, 0.9);
}

.drop-down-selector {
  min-height: 44px;
  width: 100%;

  background: #ffffff;

  border: 2px solid #e4ebf6;
  border-radius: 12px;
}

.drop-down-selector .selected-value .text {
  margin-top: 8px;
  margin-left: 20px;
  float: left;
  font-family: Inter-Medium;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  max-width: calc(100% - 54px);
  white-space: nowrap;
  overflow: hidden;
}

.drop-down-selector .selected-value .image {
  float: right;
  margin-right: 20px;
  margin-top: 8px;
  width: 7px;
}

.drop-down-selector .selected-value {
  height: 40px;
}

.drop-down-selector .values-container {
  margin-left: 20px;
  margin-right: 20px;
}

.drop-down-selector .values-container .value-item {
  height: 40px;
}

.drop-down-selected-option {
  color: #5064ff;
  font-family: Inter-SemiBold;
}

.drop-down-selector .values-container .value-item .text {
  height: 100%;

  display: flex;
  justify-content: left;
  align-items: center;
  text-overflow: ellipsis;
  overflow: hidden;
}

.dropdown-container {
  position: fixed;
  width: 209px;
}

/* Menus */

.sidebar .options {
  margin-top: 140px;
}

.menu-item {
  height: 54px;
  display: flex;
  justify-content: left;
  align-items: center;

  font-family: Inter-Medium;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;

  background-color: white;

  color: #707a88;

  margin-bottom: 14px;
}

.menu-item-selected {
  color: #222c69;
  background-color: #f1f4fd;
}

.menu-item-selected-no-background {
  color: #222c69;
}

.menu-item .image {
  margin-left: 30px;
  height: 22px;
  width: 22px;
  margin-top: -6px;
  float: left;
}

.menu-item .text {
  margin-right: 30px;
  padding-left: 20px;
  float: left;
  overflow: hidden;
  text-overflow: ellipsis;
}

.settings-line-container {
  display: flex;
  flex-direction: row;
  justify-content: left;
  margin-top: -15px;
}

.settings-line-container .lines {
  margin-left: 40px;
}

.settings-line-container .options {
  width: 100%;
  margin-top: 15px;
  margin-left: 10px;
  margin-right: 30px;
}

.sub-menu-item {
  width: 100%;
  height: 36px;
  margin-bottom: 10px;
  text-align: left;
  padding-left: 20px;
  display: flex;
  justify-content: left;
  align-items: center;
}

.sub-menu-item-selected {
  background: #f1f4fd;
  border-radius: 12px;
  color: #5064ff;
}

/* Profile */
.profile {
  width: 100%;

  position: absolute;
  bottom: 0px;
}

/* Platform detail */

.platform-detail {
  text-align: left;

  padding-left: 250px;
  padding-bottom: 60px;
}

/* Header */

.platform-header {
  width: 100%;
  height: 50px;
  margin-top: 30px;
}

.platform-header .title {
  font-size: 32px;
  font-family: Inter-SemiBold;
  font-weight: 400;

  padding-top: 5px;
}

.platform-header .left-container {
  float: left;
  margin-left: 42px;

  font-size: 32px;
  font-family: Inter-SemiBold;
  font-weight: 400;

  display: flex;
  justify-content: center;
  align-items: left;
}

.platform-header-back-button-container {
  margin-right: 20px;
}

.platform-header .filters {
  float: right;
  margin-right: 42px;
}

/* Platform selector */

.platform-selector {
  background: #ffffff;

  border: 1px solid #e4ebf6;
  border-radius: 10px;
  height: 38px;
  width: 220px;
}

.platform-selector .row {
  padding-left: 3px;
  padding-right: 3px;
}

.platform-selector-button {
  text-align: center;
  display: flex;
  justify-content: center;
  height: 30px;
  margin-top: 3px;
  align-items: center;

  opacity: 0.6;

  font-family: Inter-SemiBold;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 24px;
}

.platform-selector-button-selected {
  background: #161d48;
  border-radius: 10px;
  opacity: 1;
  color: white;
}

/* Affected users */

.affected-users {
  max-width: 400px !important;
  width: 400px !important;
}

.affected-users-container {
  border: 0.8px solid #e4ebf6;
  filter: drop-shadow(0px 0px 20px rgba(223, 229, 243, 0.5));
  border-radius: 20px;

  background-color: white;
  height: 410px;
}

.affected_users_chart {
  position: relative;
}

.affected_users_chart .legend {
  position: absolute;
  text-align: center;
  width: 180px;
  margin-left: calc(50% - 100px);
  margin-top: 115px;
}

.affected_users_chart .number {
  font-family: Inter-SemiBold;
  font-weight: 400;
  font-size: 24px;
  line-height: 22px;
}

.affected_users_chart .label {
  margin-top: 10px;
  font-family: Inter-Regular;
  font-weight: 200;
  font-size: 14px;
  line-height: 22px;
  color: #707a88;
}

.affected_users_chart .value {
  font-family: Inter-SemiBold;
  font-weight: 400;
  font-size: 20px;
  margin: 0 !important;
  margin-top: 4px !important;
  margin-left: 20px !important;
  padding: 0 !important;
  max-width: 110px;
}

.affected_users_chart .percentage {
  margin: 0 !important;
  padding: 5px !important;
  text-align: left;
  max-width: 44px;
  max-height: 25px;
  float: left;
  padding-top: 2px !important;
  margin-top: 5px !important;

  display: flex;
  align-content: center;
  justify-content: center;

  font-family: Inter-SemiBold;
  font-weight: 400;
  font-size: 14px;
  color: #949faf;

  background: #f7f8fc;
  border-radius: 4px;
  margin-left: 10px !important;
}

.affected-users-container .value-percentage-container {
  display: flex;
  /* background-color: red; */
}

/* Affected legend */

.small-rect {
  width: 12px !important;
  height: 12px !important;
  max-width: 12px !important;
  max-height: 12px !important;
  padding: 0px !important;
  float: left;
  margin-left: 20px;
  margin-top: 5px !important;
  margin-right: 10px;
}

.affected_legend .label {
  float: right;
  padding: 0px;
  margin: 0px;
}

/* Date range picker */

.date-range-option-col {
  margin: 0px !important;
  padding: 0px !important;
}

.date-range-option-row {
  max-width: 319px;

  margin-top: 16px !important;
  margin-left: 40px !important;
}

.date-range-option {
  font-family: Inter-SemiBold;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;

  color: #949faf;

  display: flex;
  justify-content: center;
  align-items: center;
  height: 32px;
}

.date-range-selected {
  background: #f7f8fc;
  border-radius: 4px;
}

/* Users on app versions */

.users-on-app-versions {
  margin-left: 60px !important;
}

.users-on-app-versions-graph {
  margin-left: 20px;
  margin-right: 20px;
}

/* Dashboard general */

.dashboard-section-title {
  font-family: Inter-Medium;
  font-style: normal;
  font-weight: 200;
  font-size: 20px;
  margin-top: 20px;
  margin-bottom: 22px;
}

/* Last messages */

.last-messages {
  margin-top: 30px;
}

.last-messages .header {
  width: 100%;
  height: 80px;
}

.last-messages .title {
  float: left;
}

.last-messages .see-all {
  margin-top: 24px;
  float: right;
}

.dashboard-last-message-row td {
  background-color: #fff;
  height: 60px;
  border-bottom: 3px solid #f7f8fc;
}

.dashboard-last-message-row .table-title {
  min-width: 300px;
}

.version-block {
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  background: #f7f8fc;

  border: 1px solid #e4ebf6;
  border-radius: 7px;

  font-family: Inter-Medium;
  font-weight: 400;
  font-size: 14px;
  text-align: center;
  letter-spacing: 0.04em;

  color: #222c69;

  height: 37px;
}

.version-block .text {
  margin-left: 10px;
  margin-right: 10px;
}

/* Type component */

.type-component {
  width: 86px;
  height: 30px;
  border-radius: 7px;

  display: inline-flex;
  justify-content: center;
  align-content: center;
  align-items: center;

  font-family: Inter-Medium;
  font-size: 14px;
  text-align: center;
}

.block-component {
  background: #ffeaf7;
  border: 1px solid #f0b4d8;
  color: #e95fb2;
}

.update-component {
  background: #fff7ed;
  border: 1px solid #f9d8ae;
  color: #ff9a51;
}

.notify-component {
  background: #edfbfa;
  border: 1px solid #bef4ee;
  color: #44d3c5;
}

/* Configure app */

.configure-app-view {
  position: absolute;
  width: calc(100% - 250px) !important;
  height: calc(100% - 80px) !important;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
}

.configure-app-container {
  max-width: 600px;
  max-height: 600px;
  background-color: #fff;
  border: 1px solid #e4ebf6;
  filter: drop-shadow(0px 0px 20px rgba(223, 229, 243, 0.5));
  border-radius: 20px;
  padding: 60px;
}

.gear-image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 240px;
}

.gear-image {
  height: 100%;
}

.message-image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 240px;
}

.message-image {
  height: 70%;
  margin-bottom: 40px;
}

.configure-app-container .title {
  margin-top: -30px;

  font-family: Inter-Medium;
  font-style: normal;
  font-weight: 200;
  font-size: 28px;

  text-align: center;

  color: #161d48;
}

.configure-app-container .subtitle {
  margin-top: 20px;

  font-family: Inter-Regular;
  font-style: normal;
  font-weight: 200;
  font-size: 18px;

  text-align: center;

  color: #161d48;

  opacity: 0.8;
}

/* Messages */

/* #messages {
  height: 100%;
  position: absolute;
  width: calc(100% - 250px);
} */

/* Subheader */

.total-messages-component {
  background: #f7f8fc;
  /* grey-200 */

  border: 1px solid #e4ebf6;
  border-radius: 12px;

  max-width: 545px !important;
}

.total-col {
  min-width: 170px !important;
  padding-left: 25px !important;
}

.message-block {
  padding-left: 0px;
  padding-right: 0px;
  border-right: 1px solid #e4ebf6;
}

.message-block-no-border {
  border-right: 0px solid #e4ebf6;
}

.message-block .title {
  padding-top: 12px;
  font-family: Inter-Bold;
  font-weight: 200;
  font-size: 12px;
  text-align: left;

  letter-spacing: 0.06em;

  color: #9aade8;
}

.message-block .number {
  font-family: Inter-Regular;
  font-weight: 200;
  font-size: 20px;

  letter-spacing: 0.06em;

  color: #9aade8;

  text-align: left;
  letter-spacing: 0.06em;

  color: #161d48;
  margin-top: 0px;
  padding-bottom: 5px;
}

.messages-subheader {
  display: flex;
  justify-content: space-between;
  align-items: end;
  margin-left: 47px;
  margin-right: 47px;

  margin-top: 40px;
}

.messages-subheader .messages {
  width: 450px;
}

/* Search bar */

.search-bar {
  width: 351px;
  height: 44px;
  background: #ffffff;

  border: 0.5px solid #e4ebf6;
  box-shadow: 0px 0px 8px rgba(223, 229, 243, 0.5);
  border-radius: 12px;
  padding-left: 20px;

  display: flex;
  justify-content: left;
  padding-top: 10px;
  padding-bottom: 10px;
}

.search-bar .image {
  width: 16px;
  margin-right: 12px;
}

.search-bar .image {
  width: 16px;
  margin-right: 12px;
}

.search-bar .search {
  width: calc(100% - 45px);
  height: 30px;
  border: 0;
  margin-top: -3px;
}

.search-bar .search::placeholder {
  font-family: Inter-Regular;
  font-weight: 400;
  font-size: 15px;

  color: #949faf;
}

/* Filters */

.selector-button {
  border: 1px solid #e4ebf6;
  border-radius: 100px;
  height: 35px;
  text-align: center;

  color: rgba(66, 75, 89, 0.7);

  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding-left: 16px;
  padding-right: 16px;
  margin-left: 16px;

  font-family: Inter-Medium;
  font-weight: 400;
  font-size: 15px;
}

.selector-button-selected {
  background: #e4ebf6;
  border-radius: 100px;

  color: #424b59;
}

/* Messages and filters */

.messages-search-and-filter {
  margin-top: 40px;
  display: flex;
  justify-content: left;
  align-items: center;
}

/* Messages list */

.messages-list-container {
  margin-top: 20px;
  margin-bottom: 40px;
}

.message-table-view-row {
  height: 105px;
  background: #ffffff;

  border-bottom: 3px solid #f7f8fc;
}

/* Create message */

.message-container-wrapper {
  /* height: 200px; */
  /* width: 100%; */
}

.message-container {
  width: 100%;

  background: #ffffff;

  border: 0.8px solid #e4ebf6;
  box-shadow: 0px 0px 20px rgba(223, 229, 243, 0.5);
  border-radius: 20px;
  padding: 20px;
}

.message-container .header {
  display: flex;
  justify-content: end;
  margin-bottom: 20px;
}

.message-settings-col {
  /* background-color: red; */
}

.message-prev-col {
  /* background-color: green; */
}

.message-section .title {
  font-family: Inter-Regular;
  font-style: normal;
  font-weight: 200;
  font-size: 18px;

  color: #707a88;
}

.separator {
  background-color: #e4ebf6;
  width: 100%;
  height: 1px;
  margin-top: 12px;
}

.message-item-title {
  font-family: Inter-SemiBold;
  font-size: 15px;
  line-height: 150%;

  text-align: left;

  color: rgba(66, 75, 89, 0.7);
}

.message-item-title-optional {
  font-family: Inter-Regular;
}

.message-field {
  background: #f7f8fc;

  border: 0.5px solid #e4ebf6;
  border-radius: 12px;

  height: 44px;
  min-width: 300px;
  padding-left: 20px;
  padding-right: 20px;
  width: 100%;
}

.message-field::placeholder {
  color: #949faf;

  font-family: Inter-Regular;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
}

.message-field-large {
  height: 85px;

  background: #f7f8fc;

  border: 0.5px solid #e4ebf6;
  border-radius: 12px;

  min-width: 300px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 10px;
  padding-bottom: 10px;

  width: 100%;
  resize: none;
}

.message-field-large::placeholder {
  color: #949faf;

  font-size: 14px;
  font-family: Inter-Regular;
  font-style: normal;
  font-weight: 500;
}

.settings-messages-column {
  min-width: 300px !important;
}

.settings-icon-column {
  /* background-color: red; */
  min-width: 150px;
}

.upload-icon-wrapper {
  margin-top: 8px;
  background: #f7f8fc;
  opacity: 0.8;
  border: 1.5px dashed #9aade8;
  border-radius: 12px;

  width: 100%;
  height: calc(100% - 60px);
  display: flex;
  justify-content: center;
  align-items: center;
}

.upload-icon-wrapper .items {
  padding: 20px;
}

.upload-icon-wrapper .items .image {
  text-align: center;
}

.upload-icon-wrapper .items .title {
  margin-top: 15px;
  font-family: Inter-SemiBold;
  font-weight: 200;
  font-size: 14px;
  text-align: center;

  color: #949faf;
}

.upload-icon-wrapper .items .subtitle {
  font-family: Inter-Regular;
  font-size: 12px;
  text-align: center;

  margin-top: 4px;

  color: #949faf;
}

.message-type {
  border: 2px solid #e4ebf6;
  filter: drop-shadow(0px 0px 20px rgba(223, 229, 243, 0.5));
  border-radius: 20px;

  padding-left: 16px;
  padding-right: 20px;
  padding-top: 20px;
  padding-bottom: 16px;
  background-color: #fff;
}

.message-type-block {
  border: 2px solid #ed79bf;
  background-color: #fff9fd;
}

.message-type-block .title {
  color: #ed79bf !important;
}

.message-type-update {
  border: 2px solid #ff9a51;
  background-color: #fffaf4;
}

.message-type-update .title {
  color: #ff9a51 !important;
}

.message-type-notify {
  border: 2px solid #44d3c5;
  background-color: #f6fdfc;
}

.message-type-notify .title {
  color: #44d3c5 !important;
}

.message-type .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 0px !important;
}

.message-type .title {
  font-family: Inter-SemiBold;
  font-size: 16px;
  text-align: left;

  letter-spacing: 0.06em;

  color: #949faf;
}

.message-type .image {
  margin-top: -8px;
}

.message-type .description {
  font-family: Inter-Regular;
  font-weight: 200;
  font-size: 12px;
  line-height: 140%;
  margin-right: 10px;

  color: #707a88;
}

.message-type .mention {
  margin-top: 10px;
  font-family: Inter-SemiBold;
  font-weight: 200;
  font-size: 12px;
  line-height: 140%;

  color: #424b59;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.message-type .mention .text {
  display: flex;
  justify-content: center;
  align-items: center;
}

.message-drop-down {
  background: #f7f8fc;

  border: 0.5px solid #e4ebf6;
  border-radius: 12px;
  width: 225px;
  max-width: 225px;
}

.current-value-container {
  height: 44px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 20px;
  margin-right: 20px;
}

.current-value-container .placeholder {
  font-family: Inter-Regular;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 150%;
  color: #949faf;
  background-color: transparent;
}

.dropdown-list {
  max-height: 140px;
  overflow: scroll;
  background: #f7f8fc;
  border-left: 0.5px solid #e4ebf6;
  border-right: 0.5px solid #e4ebf6;
  border-bottom: 0.5px solid #e4ebf6;

  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  position: absolute;
  width: 225px;
  margin-left: -0.5px;
  margin-top: -12px;
  padding-top: 12px;
  padding-bottom: 12px;
}

.current-value-container .value {
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 150%;
  background-color: transparent;
}

.message-drop-down-option {
  display: flex;
  margin-left: 15px;
  margin-right: 15px;
  height: 37px;
  justify-content: left;
  align-items: center;
}

.message-drop-down-option .value {
  margin-left: 10px;
}

.message-drop-down-option .image {
  margin-top: -2px;
}

/* Date picker */

.messages-date-picker-container {
  width: 225px;
  min-width: 225px;
  height: 44px;

  background: #f7f8fc;

  border: 0.5px solid #e4ebf6;
  border-radius: 12px;

  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
}

.messages-date-picker-container .placeholder {
  font-family: Inter-Regular;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 150%;

  background-color: transparent;

  color: #949faf;
}

.messages-date-picker-container .image {
  margin-top: -3px;
}

.date-picker-container {
  margin-top: 10px;
}

.date-time-picker {
  width: 100%;
  background-color: transparent;
  border: none;
}

.date-picker-items {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.date-picker-close-icon {
  width: 32px;
  height: 32px;
  padding: 10px;
}

.date-time-input {
  font-family: Inter-Regular;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 150%;

  color: #424b59;

  background-color: transparent;
  width: 100%;
  height: 100%;
  border: none;
}

/* Popup */

.popup-message {
  top: 0;
  left: 0;
  position: fixed;
  height: 100%;
  width: 100%;
  background: rgba(168, 170, 192, 0.5);
  backdrop-filter: blur(3px);

  display: flex;
  justify-content: center;
  align-items: center;
}

.popup-container {
  width: 690px;
  background-color: white;

  border-top: 5px solid #5064ff;
  filter: drop-shadow(0px 0px 20px rgba(223, 229, 243, 0.5));
  border-radius: 20px;
  padding-top: 20px;

  overflow: hidden;
}

.popup-container .content {
  padding-left: 20px;
  padding-right: 20px;
}

.popup-destructive {
  border-top: 5px solid #ff4e59;
}

.popup-close-icon {
  width: 20px;
  height: 20px;
}

.popup-message-icon {
  width: 45px;
  height: 45px;
  margin-left: 20px;
  margin-right: 20px;
}

.popup-message .title-container {
  display: flex;
  justify-content: left;
  align-items: center;
}

.popup-message .description-container {
  margin-left: 85px;
  margin-right: 60px;
  margin-top: 15px;
  margin-bottom: 20px;

  font-family: Inter-Regular;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 170%;

  color: #161d48;

  opacity: 0.6;
}

.popup-message .title-container .title {
  font-family: Inter-SemiBold;
  font-style: normal;
  font-weight: 200;
  font-size: 24px;
  line-height: 130%;
  letter-spacing: -0.01em;

  color: #161d48;
}

.popup-container .header {
  justify-content: right;
  display: flex;
}

.popup-container .footer-container {
  background: #f7f8fc;
  height: 83px;

  display: flex;
  align-items: center;
  justify-content: right;
  padding-right: 30px;
}

.popup-cancel-button {
  margin-left: 20px;
  margin-right: 20px;

  font-family: Inter-Medium;
  font-style: normal;
  font-weight: 200;
  font-size: 18px;
  line-height: 24px;

  color: #707a88;
}

.popup-action-button {
  border-radius: 12px;
  height: 50px;
  padding-left: 40px;
  padding-right: 40px;

  font-family: Inter-SemiBold;
  font-style: normal;
  font-weight: 200;
  font-size: 18px;
  line-height: 24px;

  background: #e9ebff !important;
  border: 2px solid #5064ff !important;
  color: #5064ff;
}

.popup-action-button-destructive {
  background: #fff8f9 !important;
  border: 2px solid #ff6f78 !important;
  color: #ff6f78;
}

/* White text input */

.white-input-field-container {
  height: 64px;
  width: 100%;
  background: #ffffff;
  /* grey-200 */

  border: 1px solid #e4ebf6;
  border-radius: 14px;
  padding-left: 20px;
  padding-right: 20px;

  display: flex;
  align-items: center;
  justify-content: space-between;
}

.white-input-field-container .input {
  height: 100%;
  width: 100%;
}

.white-input-field-container .image {
  margin-left: 10px;
  margin-top: -4px;
}

.white-input-field {
  width: 100%;
  height: 100%;
  border: none;
  background-color: transparent;

  font-family: Inter-Regular;
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 130%;
  letter-spacing: -0.01em;

  color: #424b59;
}

.white-input-field::placeholder {
  color: #949faf;
}

/* Members */

.no-margins-container {
  margin: 0px !important;
  padding: 0px !important;
}

.one-column-field-size {
  width: 424px;
  min-width: 350px !important;
  max-width: 424px !important;
}

.one-column-small-field-size {
  width: 320px;
  min-width: 250px !important;
  max-width: 320px !important;
}

.one-column-large-field-size {
  width: 470px;
  min-width: 450px !important;
  max-width: 470px !important;
  margin-left: 60px;
}

.two-columns-fields-size {
  width: 848px;
  min-width: 700px !important;
  max-width: 848px !important;
}

.two-columns-small-fields-size {
  width: 848px;
  min-width: 700px !important;
  max-width: 848px !important;
}

.member-middle-col {
  display: flex;
  align-items: center;
  justify-content: left;
  align-content: center;
  height: calc(100% - 70px);
}

.member-row {
  height: 60px;
  background: #ffffff;

  border-bottom: 3px solid #f7f8fc;
}

.table-name {
  padding-left: 30px;
  font-family: Inter-SemiBold;
  font-style: normal;
  font-size: 15px;
  line-height: 20px;

  color: #424b59;
}

.table-email {
  font-family: Inter-Regular;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;

  color: #707a88;
}

.table-role {
  text-align: center;
}

.table-member-action {
  text-align: center;
  padding-right: 10px;
}

.member-role-container {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
}

.member-role {
  background: #f7f9fc;
  border: 1px solid #e4ebf7;
  border-radius: 7px;
  width: 67px;
  height: 24px;

  display: flex;
  justify-content: center;
  align-items: center;
}

.member-role .text {
  font-family: Inter-Medium;
  font-style: normal;
  font-size: 10px;
  line-height: 12px;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 0.04em;

  color: #949faf;
}

/* Integrations */

.integration-description {
  margin-top: -10px !important;
  font-family: Inter-Regular;
  font-style: normal;
  font-weight: 200;
  font-size: 15px;
  line-height: 170%;

  color: #161d48;

  opacity: 0.6;

  margin-bottom: 32px;
}

.integration-description-small {
  margin-top: -10px !important;
  font-family: Inter-Regular;
  font-style: normal;
  font-weight: 200;
  font-size: 14px;
  line-height: 170%;

  color: #161d48;

  opacity: 0.8;

  margin-bottom: 20px;
}

.integration-description-small a {
  color: #5064ff;
  text-decoration: none;
  font-family: Inter-SemiBold;
}

.integration-description a {
  color: #5064ff;
  text-decoration: none;
  font-family: Inter-SemiBold;
}

.copy-to-clipboard-container {
  margin-top: 12px;
  display: flex;
  justify-content: end;
}

.linked-applications-container {
  overflow: hidden;
  border: 0.8px solid #e4ebf6;
  border-radius: 20px;
}

.linked-application {
  height: 71px;
  background: #ffffff;
  /* grey-100 */

  border-bottom: 3px solid #f7f8fc;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.linked-application .icon {
  margin-left: 30px;
  width: 35px;
}

.linked-application .platform-container {
  margin-left: 30px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
}

.linked-application .platform-container .platform {
  display: flex;
  margin-left: 20px;
  justify-content: start;
  align-items: center;
  width: 70px;

  font-family: Inter-SemiBold;
  font-style: normal;
  font-weight: 200;
  font-size: 16px;
  line-height: 20px;

  color: #424b59;
}

.linked-application .status {
  width: 117px;
}

.linked-application .identifier {
  width: 320px;
  margin-left: 10px;
  margin-right: 10px;

  font-family: Inter-Light;
  font-weight: 200;
  font-size: 14px;
  line-height: 20px;

  color: #707a88;
}

.linked-application .action {
  width: 114px;
  margin-right: 30px;
}

.integration-status-container {
  height: 28px;
  width: 117px;
  border-radius: 7px;

  font-family: Inter-SemiBold;
  font-style: normal;
  font-weight: 200;
  font-size: 10px;
  line-height: 12px;
  text-align: center;
  letter-spacing: 0.04em;

  color: #949faf;

  display: flex;
  justify-content: center;
  align-items: center;
}

.integration-status-pending {
  color: #949faf;
  background: #f7f9fc;
  border: 1px solid #e4ebf7;
}

.integration-status-connected {
  background: #eefff7;
  border: 1px solid #c2f5d7;
  color: #5dcc97;
}

.integration-status-not-connected {
  background: #fffafb;
  border: 1px solid #ffbdc3;
  color: #ff6f78;
}

.configure-button-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.edit-button-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.configure-button {
  border: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 7px 22px;
  gap: 10px;

  width: 114px;
  height: 34px;

  background: #5064ff;
  border-radius: 10px;

  font-family: Inter-Medium;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;

  color: #ffffff;
}

.edit-button {
  background-color: white;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 7px 22px;

  width: 114px;
  height: 34px;
  left: 762px;
  top: 18px;

  border: 1.5px solid #8492ff;
  border-radius: 10px;

  font-family: Inter-Medium;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;

  color: #5064ff;
}

/* Configure integration */

.platform-type {
  display: flex;
  justify-content: left;
  align-items: center;
}

.platform-type .title {
  font-family: Inter-Medium;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 20px;

  color: #424b59;

  margin-left: 10px;
}

.sdk-code-field {
  margin-top: 18px;
}

.library-selector-container {
  margin-left: -20px;
  margin-bottom: 16px;
}

/* Copy field */

.copy-field {
  display: flex;
  justify-content: center;
  align-content: center;
}

.copy-field-large {
  height: 115px;
}

/* Profile */

.logout-button-container {
  display: flex;
  justify-content: end;
  align-content: end;
  align-items: end;
}

.logout-button-items {
  display: flex;
  justify-content: end;
  align-content: end;
  align-items: end;

  font-family: Inter-Medium;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  display: flex;
  align-items: center;

  color: #ff6f78;
}

.logout-button-items .image {
  margin-right: 10px;
}

/* Subscriptions */

.subscription-type-selector {
  background: #ffffff;

  border: 1px solid #e4ebf6;
  border-radius: 10px;
  height: 38px;
  width: 200px;
}

.subscription-type-selector .row {
  padding-left: 3px;
  padding-right: 3px;
}

.subscription-membership-container {
  border: 0.8px solid #e4ebf6;
  filter: drop-shadow(0px 0px 20px rgba(223, 229, 243, 0.5));
  border-radius: 20px;

  background-color: white;

  padding: 32px;
}

.subscription-button-container {
  background: #ffffff;
  /* purple-200 */

  border: 1.5px solid #d6def6;
  border-radius: 12px;

  height: 48px;
  display: flex;
  justify-content: center;

  color: red;
}

.subscription-button-container .subscribe-button {
  font-family: Inter-Medium;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;

  color: #5064ff;
}

.subscribe-button-container-highlighted {
  background: #5064ff;
  border: 1.5px solid #5064ff;
}

.subscribe-button-container-highlighted .subscribe-button {
  color: #fff;
}

.subscription-membership-container .type {
  font-family: Inter-SemiBold;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;

  color: #161d48;
  margin-top: 20px;
}

.subscription-membership-container .price-container {
  margin-top: 32px;
}

.subscription-membership-container .price-container .value {
  font-family: Inter-Bold;
  font-style: normal;
  font-weight: 200;
  font-size: 44px;
  line-height: 24px;

  color: #161d48;
}

.subscription-membership-container .price-container .month {
  font-family: Inter-SemiBold;
  font-style: normal;
  font-weight: 200;
  font-size: 20px;
  line-height: 24px;
  /* identical to box height, or 120% */

  /* GREY-700 */

  color: #707a88;
}

.subscription-membership-container-highlighted .type {
  color: #5d7aff;
}

.subscription-membership-container-highlighted {
  border: 1.5px solid #5d7aff;
}

.perks-container {
  margin-top: 50px;
  margin-bottom: 50px;
}

.subscription-membership-perk {
  font-family: Inter-Regular;
  font-style: normal;
  font-weight: 200;
  font-size: 18px;
  line-height: 160%;
  /* identical to box height, or 24px */

  /* Grey-800 */

  color: #424b59;

  display: flex;
  justify-content: left;

  margin-bottom: 16px;
}

.subscription-membership-perk-highlighted {
  font-family: Inter-SemiBold;
  font-style: normal;
  font-weight: 200;
  font-size: 18px;
  line-height: 160%;
  /* identical to box height, or 24px */

  /* Grey-800 */

  color: #424b59;

  display: flex;
  justify-content: left;

  margin-bottom: 16px;
}

.subscription-membership-perk-highlighted .text-container {
  padding-left: 10px;
}

.subscription-membership-perk .text-container {
  padding-left: 10px;
}

.subscription-current-plan-container {
  border: 0.8px solid #e4ebf6;
  filter: drop-shadow(0px 0px 20px rgba(223, 229, 243, 0.5));
  border-radius: 20px;

  background-color: white;

  height: 353px;
  padding: 32px;

  display: flex;
  flex-direction: column;

  align-items: start;

  min-width: 454px;
}

.subscription-current-plan-container .title {
  font-family: Inter-SemiBold;
  font-style: normal;
  font-weight: 200;
  font-size: 26px;
  line-height: 24px;

  color: #5d7aff;

  margin-bottom: 42px;
  margin-top: 20px;
}

.subscription-current-plan-container .price {
  font-family: Inter-SemiBold;
  font-style: normal;
  font-size: 50px;
  line-height: 24px;

  color: #161d48;
}

.subscription-current-plan-container .period {
  font-family: Inter-Medium;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 24px;
  color: #707a88;
}

.subscription-current-plan-container .bottom-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.subscription-current-plan-container .cancel-button {
  font-family: Inter-Medium;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  letter-spacing: -0.02em;

  color: #ff6f78;
  padding-top: 15px;
}

.subscription-current-plan-container .upgrade-button {
  font-family: Inter-Medium;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: -0.02em;

  color: #fff;

  /* purple-400-primary */

  background: #5064ff;
  border-radius: 12px;

  height: 44px;
  width: 177px;
}

.subscription-billing-informaion-container {
  border: 0.8px solid #e4ebf6;
  filter: drop-shadow(0px 0px 20px rgba(223, 229, 243, 0.5));
  border-radius: 20px;
  background-color: white;

  padding: 32px;
  height: 353px;
}

.subscription-billing-informaion-container .description {
  font-family: Inter-Regular;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 130%;
  /* or 18px */

  text-align: left;
  letter-spacing: -0.01em;

  color: #161d48;
}

.subscription-billing-informaion-container .value {
  font-family: Inter-SemiBold;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 130%;

  color: #161d48;

  margin-top: 10px;
}

.billing-separator {
  height: 1.5px;
  background-color: #e4ebf6;
  margin-left: -32px;
  margin-right: -32px;

  margin-top: 20px;
  margin-bottom: 20px;
}

.update-payment-button {
  font-family: Inter-SemiBold;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 130%;
  /* text-align: right; */
  margin-left: -5px;
  margin-top: 10px;
  letter-spacing: -0.01em;

  color: #5064ff;
}

.view-invoices-container {
  display: flex;
  justify-content: right;
}

.view-invoices-button {
  font-family: Inter-SemiBold;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 130%;
  /* or 23px */

  text-align: right;
  letter-spacing: -0.01em;

  /* purple-400-primary */

  color: #5064ff;
}

.subscription-payment-failed-container {
  background: #fffafb;
  border: 2px solid #ffdee1;
  border-radius: 14px;
  height: 60px;
  margin-bottom: 20px;
  display: flex;

  justify-content: space-between;
  align-items: center;
  margin-left: 10px;

  padding-left: 20px;
  padding-right: 20px;
}

.subscription-payment-failed-container .image-text-container {
  display: flex;
  flex-direction: row;
}

.subscription-payment-failed-container .image-text-container .image {
  margin-left: 12px;
}

.subscription-payment-failed-container .image-text-container .text {
  margin-left: 10px;
  margin-top: 4px;

  font-family: Inter-Regular;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 130%;
  /* or 18px */

  letter-spacing: -0.01em;

  color: #ff6f78;
}

.subscription-payment-failed-container .update-payment-button {
  font-family: Inter-SemiBold;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 130%;
  /* identical to box height, or 21px */

  text-align: right;
  letter-spacing: -0.01em;

  color: #ff6f78;

  margin-top: 3px;
  margin-right: 12px;
}

/* General */

.disable-input-field {
  background-color: #e4ebf6;
}

.code-field {
  font-family: Inter-Medium;
  font-style: normal;
  font-size: 18px;
  line-height: 130%;

  letter-spacing: -0.01em;

  color: #424b59;

  opacity: 1;
}

.code-highlighted {
  color: #5064ff;
}

.red {
  background-color: red;
}

.platform-margins {
  margin-left: 47px !important;
  margin-right: 47px !important;
}

.simple-button-with-image {
  border: transparent;

  background-color: transparent;
  border: 0px;

  background: #5064ff;
  border-radius: 12px;

  margin: 0px;
  padding: 0px;

  text-align: left;

  height: 40px;
}

.simple-button-with-image .title {
  font-family: Inter-Medium;
  font-style: normal;
  font-weight: 200;
  font-size: 16px;

  color: #ffffff;
  margin-right: 20px;
  margin-left: 10px;
}

.simple-button-with-image .image {
  margin-left: 15px;
  margin-top: -4px;
}

.table-version {
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.table-version-messages {
  text-align: center;
  padding-left: 30px;
  padding-right: 30px;
}

.table-title {
  font-family: Inter-SemiBold;
  font-weight: 400;
  font-size: 15px;
  text-align: left;
  letter-spacing: 0.04em;

  color: #424b59;
}

.table-message-and-title {
  text-align: center;
}

.table-message-and-title .title {
  font-family: Inter-SemiBold;
  font-weight: 400;
  font-size: 15px;
  text-align: left;
  letter-spacing: 0.04em;

  color: #424b59;
}

.table-message-and-title .message {
  font-family: Inter-Regular;
  font-weight: 400;
  font-size: 15px;
  text-align: left;
  font-size: 15px;
  line-height: 150%;

  color: #707a88;
}

.table-date {
  font-family: Inter-Regular;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 180%;

  text-align: right;
  letter-spacing: 0.05em;

  line-break: strict;
  white-space: pre;

  color: #707a88;

  min-width: 130px;
  text-align: center;
}

.table-action {
  min-width: 100px;
  text-align: center;
  padding-right: 30px;
}

.table-message {
  font-family: Inter-Regular;
  font-weight: 400;
  font-size: 15px;
  text-align: left;

  color: #707a88;
}

.table-affected {
  font-family: Inter-Medium;
  font-weight: 400;
  font-size: 15px;
  text-align: right;

  color: #424b59;

  padding-right: 30px;
}

.table-type {
  text-align: center;
  padding-left: 10px;
  padding-right: 10px;
}

.table-container {
  border: 0.8px solid #e4ebf6;
  filter: drop-shadow(0px 0px 20px rgba(223, 229, 243, 0.5));
  border-radius: 20px;

  background-color: white;
  overflow: hidden;
}

.platform-table {
  width: 100%;
}

.platform-table tr th {
  background-color: #f7f8fc;
  height: 68px;

  color: #949faf;

  font-family: Inter-Bold;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;

  letter-spacing: 0.06em;
}

.table-header {
  background: #f7f8fc;
  height: 68px;
  min-height: 68px;
}

.master-content {
  margin-top: 20px;
}

.slim-button {
  height: 54px;
}

.simple-button-text {
  border: transparent;

  background-color: transparent;
  border: 0px;

  font-family: Inter-Medium;
  font-style: normal;
  font-weight: 400;
  font-size: 17px;

  color: #222c69;

  margin: 0px;
  padding: 0px;

  text-align: left;
}

.accent {
  background-color: #5064ff;
}

.secondary-accent {
  background-color: #c5cfff;
}

.line-separator {
  height: 0px;
  width: 100%;
  border: 1px solid #e4ebf6;
}

.text-left {
  text-align: left;
}

.title {
  font-style: normal;
  font-weight: 600;
  font-size: 35px;
  font-family: Inter-SemiBold;

  letter-spacing: -0.01em;
}

.subtitle {
  font-family: Inter-Light;
  font-weight: 400;
  font-size: 18px;

  letter-spacing: -0.01em;
  opacity: 0.7;
}

.subsubtitle {
  font-family: Inter-Medium;
  font-weight: 400;
  font-size: 16px;

  color: #85889f;
}

.full-screen-item {
  position: absolute;
  width: 100%;
  height: 100%;
}

.disabled {
  opacity: 0.5;
}

.no-padding {
  padding: 0px !important;
}

.transparent-button {
  background-color: transparent;
  border: 0;
}
/*Site Header */
.site-header {
  position: fixed;
  top: 0px;
  z-index: 9999;
  display: flex;
  column-gap: 20px;
  justify-content: space-between;
  padding: 0 140px;
  align-items: center;
  background: #ffffff;
  /* grey-200 */
  border-bottom: 1px solid #e4ebf6;
  min-width: 1056px;
  width: 100%;
  height: 72px;
}

.site-header-small {
  position: fixed;
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 17px 35px;
  z-index: 5;
  background-color: white;
}
.site-header-buttons-container {
  display: flex;
  align-items: center;
  column-gap: 40px;
}
.site-header-button {
  border: none;
  background: transparent;
}
.site-header-mobile-nav {
  display: flex;
  z-index: 5;
  flex-direction: column;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 65px;
  padding: 41px 30px;
  background: #161d48;
  justify-content: space-between;
}

.site-header-mobile-nav li {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  color: white;
}

.site-header-mobile-normal-option {
  color: white;
  font-family: Inter-Regular;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  text-decoration: none;
}

.site-header-mobile-colored-option {
  color: #808eff;
}
.site-header-mobile-button {
  margin-bottom: 50px;
  border: none;
  background: #808eff;
  border-radius: 10px;
  padding: 13px 0;
  color: white;
  font-family: Inter-Regular;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
}
.site-header-action-button {
  /* purple-400-primary */
  background: #5064ff;
  border-radius: 10px;
  font-family: Inter-Medium;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  color: #ffffff;
  border: none;
  padding: 13px 24px;
}

.site-header-normal-link {
  font-family: Inter-Regular;
  text-decoration: none;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  text-align: right;
  /* purple-900 */
  color: #161d48;
}
.site-header-bold-link {
  font-family: Inter-SemiBold;
  text-decoration: none;
  font-style: normal;

  font-size: 18px;
  line-height: 24px;
  text-align: right;
  /* purple-900 */
  color: #161d48;
}
@media (max-width: 1200px) {
  .site-header {
    padding: 11px 100px;
  }
  .site-header-buttons-container {
    column-gap: 25px;
  }
}
/* Site Footer*/
.site-footer-cotainer {
  background: #161d48;
  padding: 55px 250px;
}
.site-footer-left-side-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.footer-copyright {
  text-align: start;
  font-family: Inter-Regular;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: rgba(255, 255, 255, 0.8);
}
.footer-copyright span {
  font-family: Inter-SemiBold;
}

.footer-social-media-wrapper {
  display: flex;
  align-items: center;
  column-gap: 21px;
}

.footer-social-media-button {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  width: 50px;
  border: none;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 50%;
}
.footer-utils {
  color: rgba(255, 255, 255, 0.8);
}

.footer-utils-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 10px;
  width: 100%;
}
.footer-utils-wrapper p {
  font-size: 18px;
  color: white;
  font-family: Inter-Medium;
}
.footer-utils-wrapper a {
  font-size: 16px;
  font-family: Inter-Medium;
  color: rgba(255, 255, 255, 0.6) !important;
  text-decoration: none;
  text-align: left;
  width: 100%;
}

@media (max-width: 960px) {
  .footer-copyright {
    font-size: 12px;
  }
  .footer-utils-wrapper p {
    font-size: 16px;
  }
  .footer-utils-wrapper a {
    font-size: 16px;
  }
  .footer-copyright-wrapper {
    margin-top: 60px;
  }
  .site-footer-cotainer {
    padding: 61px 40px;
  }
  .footer-social-media-wrapper {
    margin-top: 50px;
  }
  .footer-utils {
    margin-top: 30px;
  }
}
/* Faq Page*/

#faq {
  background-color: #f7f8fc;
}

.faq-page-container {
  display: flex;
  flex-direction: column;
  padding: 100px 0;
  row-gap: 100px;
  background-color: #f7f8fc;
}
.faq-page-title {
  display: flex;
  align-items: center;
  justify-content: center;
}
.faq-page-title h1 {
  font-family: Inter-SemiBold;
  font-style: normal;
  font-weight: 700;
  font-size: 60px;
  text-align: center;
  /* purple-900 */
  color: #161d48;
}
.faq-items-list {
  display: flex;
  flex-direction: column;
}
.faq-item-wrapper {
  border-bottom: 3px solid #e4ebf6;
}
.faq-title {
  display: flex;
  justify-content: space-between;
  align-items: start;
  padding: 30px 0;
}
.faq-arrow-button {
  cursor: pointer;
}
.faq-title p {
  text-align: start;
  margin: 0;
  font-family: Inter-Medium;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 130%;
  /* purple-900 */
  color: #161d48;
}
.faq-text {
  display: flex;
  justify-content: left;
}
.faq-text p {
  text-align: start;
  font-family: Inter-Regular;
  text-align: left;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 160%;
  /* purple-900 */
  color: #161d48;
  margin-bottom: 32px;
}

@media (max-width: 960px) {
  .faq-page-container {
    padding: 70px 35px;
    row-gap: 40px;
  }
  .faq-page-title h1 {
    font-size: 34px;
  }
  .faq-title p {
    font-size: 18px;
  }
  .faq-text p {
    font-size: 16px;
  }
}

/* Pricing Page */
.pricing-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 100px 0;
}
.pricing-page-title {
  max-width: 860px !important;
  display: flex;
  justify-content: center;
}
.pricing-page-title h1 {
  font-family: Inter-SemiBold;
  font-style: normal;
  font-weight: 700;
  font-size: 60px;
  line-height: 130%;
  text-align: center;
  letter-spacing: -2px;
  color: #161d48;
  text-align: center;
}
.pricing-page-title span {
  background: linear-gradient(120.98deg, #5c62ff 8.43%, #a3aaff 52.81%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}
.pricing-page-subtitle {
  margin-top: 32px !important;
  max-width: 700px !important;
  display: flex;
  justify-content: center;
}
.pricing-page-subtitle p {
  max-width: 700px;
  font-family: Inter-Regular;
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 150%;
  text-align: center;
  /* purple-900 */
  color: #161d48;
  opacity: 0.8;
}
.pricing-page-options-wrapper {
  background: #ffffff;
  /* grey-200 */
  border: 1px solid #e4ebf6;
  border-radius: 10px;
  padding: 2px;
  margin-top: 100px;
}
.pricing-page-option-button {
  border-radius: 10px;
  border: none;
  background-color: transparent;
  color: #161d48;
  text-transform: capitalize;
  padding: 3px 16px;
}

.pricing-button-active {
  background: #161d48;
  color: white;
}
.pricing-button-inactive {
  opacity: 0.6;
}

.pricing-page-sub-plans-wrapper {
  display: block;
  margin-top: 40px;
  display: flex;
  column-gap: 47px;
  justify-content: center;
}
.pricing-carousel-wrapper {
  display: flex;
  justify-content: center;
  position: relative;
  margin-top: 30px;
  display: none;
}

@media (max-width: 960px) {
  .pricing-page {
    padding: 94px 0 !important;
  }
  .pricing-page-title h1 {
    font-size: 33px;
  }
  .pricing-page-subtitle p {
    font-size: 18px;
    line-height: 24px;
  }
  .pricing-page-options-wrapper {
    margin-top: 64px;
  }
  .pricing-page-sub-plans-wrapper {
    margin-top: 28px;
  }
  .pricing-carousel-wrapper {
    display: block;
  }
  .pricing-page-sub-plans-wrapper {
    display: none;
  }
}

/* Pricing Card */
.pricing-card {
  border: 0.8px solid #e4ebf6;
  filter: drop-shadow(0px 0px 20px rgba(223, 229, 243, 0.5));
  background: #ffffff;
  border-radius: 20px;
  padding: 50px 32px;
  display: flex;
  flex-direction: column;
  row-gap: 40px;
  align-items: start;
  width: 316px;
}
.pricing-card-type {
  font-family: Inter-Regular;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  /* purple-900 */
  color: #5d7aff;
}

.pricing-card-type-secondary {
  color: #161d48;
}
.pricing-card-price {
  font-family: Inter-Bold;
  font-style: normal;
  font-weight: 700;
  font-size: 44px;
  line-height: 24px;
  /* purple-900 */
  color: #161d48;
}
.pricing-card-price span {
  font-family: Inter-SemiBold;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  /* purple-900 */
  color: #707a88;
}
.pricing-card-check-list {
  display: flex;
  flex-direction: column;
  row-gap: 15px;
  column-gap: 10px;
  height: 100%;
  width: 266px;
}

.pricing-card-check-item {
  display: flex;
  column-gap: 10px;
}
.pricing-card-check-item svg {
  margin-top: 8px;
}
.pricing-card-check-item-text {
  font-family: Inter-Regular;
  font-style: normal;
  width: 100%;
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;
  /* Grey-800 */
  color: #424b59;
  text-align: start;
  margin: 0;
}

.pricing-card-check-item-text-bold {
  font-family: Inter-SemiBold;
}

.pricing-card-button {
  width: 100%;
  height: 48px;
  border-radius: 12px;
  background: #5064ff;
  border: 0px;
  font-family: Inter-Medium;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;

  color: #ffffff;
}

.pricing-card-button-secondary {
  background: #ffffff;
  color: #5064ff;
  border: 1.5px solid #d6def6;
  font-family: Inter-SemiBold;
  font-weight: 400;
  font-size: 20px;
  height: 48px;
}

/* For teams */
.for-teams-page {
  display: flex;
  flex-direction: column;
  padding: 100px 150px 0 150px;
  position: relative;
}
.for-teams-page-background {
  background: #f7f8fc;
}

.for-teams-title-wraper {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
}

.for-teams-page-title {
  font-family: Inter-Bold;
  font-style: normal;
  font-weight: 700;
  font-size: 60px;
  line-height: 110%;
  text-align: center;
  letter-spacing: -2px;
  /* purple-900 */
  color: #161d48;
}

.for-teams-page-subtitle {
  font-family: Inter-Regular;
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 150%;
  /* purple-900 */
  color: #161d48;
  opacity: 0.8;
}

.for-page-decorated-text {
  position: relative;
}

.for-page-decorated-text svg {
  position: absolute;
  left: 0;
}

.for-teams-page-content {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-top: 100px;
}

.for-teams-page-left-group {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.for-teams-page-right-group {
  display: block;
  position: absolute;
  top: 0;
  right: 0;
}
@media (max-width: 960px) {
  .for-teams-page-background {
    background: #eef1f7;
  }
  .for-teams-page {
    padding: 83px 32px 0 32px;
  }
  .for-teams-title-wraper {
    row-gap: 16px;
  }
  .for-teams-page-title {
    font-size: 33px;
  }
  .for-teams-page-subtitle {
    font-size: 18px;
  }
  .for-page-decorated-text svg {
    width: 50px;
    top: -30px;
  }
  .for-teams-page-content {
    margin-top: 50px;
  }
}

.spinner {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 100vh;
  margin-left: calc(50% - 29px);
  z-index: 1000;
}

/* Reach users page*/
.reach-users-page {
  position: relative;
  display: flex;
  padding: 100px 136px 0 136px;
}
.reach-users-page-background {
  background: linear-gradient(
    113.61deg,
    #dce5ff 0.5%,
    rgba(231, 237, 255, 0.4) 100%
  );
  overflow: hidden;
}
.reach-users-text-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  row-gap: 53px;
  max-width: 550px;
}
.reach-users-asset-container {
}
.reach-users-asset {
}

.reach-users-page-title {
  text-align: left;
  font-family: Inter-Bold;
  font-style: normal;
  font-size: 60px;
  line-height: 110%;
  /* purple-900 */
  color: #161d48;
}
.reach-users-page-text {
  text-align: left;
  font-family: Inter-Regular;
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 150%;

  /* purple-900 */

  color: #161d48;

  opacity: 0.8;
}
.reach-users-page-button {
  max-width: 285px;
}
.reach-users-asset {
  position: relative;
  height: 100%;
  right: 0;
  bottom: 0;
}
@media (max-width: 1200px) {
  .reach-users-page {
    margin-top: 50px;
  }
}
@media (max-width: 960px) {
  .reach-users-page {
    padding: 100px 30px 0 30px;
    flex-direction: column;
    align-items: center;
    margin-top: 0;
  }
  .reach-users-text-container {
    align-items: center;
    row-gap: 25px;
  }
  .reach-users-page-title {
    text-align: center;
    font-size: 33px;
  }
  .reach-users-page-text {
    text-align: center;
    font-size: 18px;
  }
  .reach-users-page-button {
    width: 162px;
  }
}
/* Fold3 page*/
.fold3-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 100px 200px 0 200px;
  row-gap: 32px;
}

.fold3-title {
  position: relative;
  max-width: 800px;
  font-family: Inter-Bold;
  font-style: normal;
  font-weight: 700;
  font-size: 60px;
  line-height: 110%;
  /* or 66px */
  /* purple-900 */
  color: #161d48;
}
.fold-3-asset {
  position: relative;
  width: 100%;
  margin-bottom: -130px;
  z-index: 2;
}

@media (max-width: 960px) {
  .fold3-title {
    font-size: 33px;
  }
  .fold3-page {
    padding: 70px 30px 0 30px;
    row-gap: 20px;
  }
  .fold-3-asset {
    position: relative;

    margin-bottom: -80px;
  }
}
/*Fold1 page*/
.fold1-page {
  padding: 120px 120px 80px 120px;
  overflow: hidden;
  position: relative;
}
.fold1-decoration {
  position: absolute;
  z-index: 0;
  left: -100px;
}
.fold1-decoration2 {
  position: absolute;
  z-index: 0;
  top: 400px;
  right: 0px;
}
.fold1-decoration-small {
  position: absolute;
  z-index: 0;
  left: 0;
}
.fold1-asset {
  height: 100%;
  width: 100%;
}
.fold-1-last-asset {
  margin-top: -150px;
}
.fold-1-title {
  font-family: Inter-Bold;
  font-style: normal;
  font-weight: 700;
  font-size: 60px;
  line-height: 110%;
  letter-spacing: -2px;
  /* purple-900 */
  color: #161d48;
  max-width: 860px;
}
.fold-1-subtitle {
  font-family: Inter-Regular;
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 150%;
  text-align: center;
  /* purple-900 */
  color: #161d48;
  max-width: 840px;
  margin-top: 34px;
}
.fold-1-button-wrapper {
  position: relative;
  width: 191px;
  margin-bottom: 50px;
}
.fold-1-button-decoration {
  position: absolute;
  top: 40px;
  right: -70px;
}

.fold-1-assets-wrapper {
  display: flex;
}
.fold-1-asset {
  background: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(5px);
}
.fold-1-asset-wrapper {
  display: flex;
  align-items: baseline;
  justify-content: baseline;
}
.fold-1-asset-wrapper-small {
  display: flex;
  flex-direction: column;
  margin-bottom: -150px;
  overflow: hidden;
}
.last-asset {
  margin-top: -150px;
}
@media (max-width: 960px) {
  .fold1-page {
    margin-top: 65px;
    padding: 50px 30px;
  }

  .fold-1-subtitle {
    font-size: 18px;
    margin-top: 22px;
  }
  .fold-1-title {
    font-size: 40px;
  }
}
/* How work page */
.how-work-page {
  display: flex;
  flex-direction: column;
  background: #f7f8fc;
  padding: 100px 160px;
}
.how-work-page-title-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 32px;
}
.how-work-page-rows-wrapper {
  display: flex;
  flex-direction: column;
  margin-top: 100px;
  row-gap: 140px;
}
.how-work-page-title {
  font-family: Inter-SemiBold;
  font-style: normal;
  font-weight: 700;
  font-size: 60px;
  line-height: 140%;
  text-align: center;
  letter-spacing: -2px;
  /* purple-900 */
  color: #161d48;
}
.how-work-page-subtitle {
  max-width: 750px;
  font-family: Inter-Regular;
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 150%;
  text-align: center;
  /* purple-900 */
  color: #161d48;
}
.how-work-page-row {
  display: flex;
  align-items: center;
}
.how-work-row-asset {
  max-width: 430px;
}

.how-work-row-title {
  text-align: start;
  font-family: Inter-SemiBold;
  font-style: normal;
  font-weight: 400;
  font-size: 30px;
  line-height: 45px;
  letter-spacing: -1px;
  /* purple-900 */
  color: #161d48;
  margin: 0;
}
.how-work-row-subtitle {
  text-align: start;
  font-family: Inter-Regular;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 150%;
  /* purple-900 */
  color: #161d48;
  margin-top: 30px;
}
.how-work-row-text-wrapper {
  display: flex;
  column-gap: 30px;
  min-width: 330px;
}
.how-work-row-reverse {
  flex-direction: row-reverse;
}

@media (max-width: 1400px) {
  .how-work-row-asset {
    max-width: 580px;
    /* margin: -40px 0; */
  }
}
@media (max-width: 1353px) {
  .how-work-row-asset {
    margin: 0;
    max-width: 480px;
  }
  .how-work-page {
    padding: 73px 32px;
  }
}
@media (max-width: 960px) {
  .how-work-page {
    padding: 73px 32px;
  }
  .how-work-page-title-wrapper {
    row-gap: 22px;
  }
  .how-work-page-title {
    font-size: 40px;
    line-height: 33px;
  }
  .how-work-page-subtitle {
    font-size: 18px;
    line-height: 24px;
  }
  .how-work-row-asset {
    margin-bottom: 10px;
  }
  .how-work-row-title {
    font-size: 28px;
    line-height: 30px;
    margin-top: 60px;
    margin-bottom: 20px;
    text-align: center;
  }
  .how-work-row-subtitle {
    margin-top: 10px;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    margin-bottom: 60px;
  }
  .how-work-page-rows-wrapper {
    row-gap: 30px;
    margin-top: 50px;
  }
  .how-work-page-row {
    flex-direction: column;
  }
  .how-work-row-reverse {
    flex-direction: column;
  }
}

.mobile-message-preview-container {
  /* background-color: yellow; */
  display: flex;
  margin-top: 24px;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: relative;
}

.mobile-message-preview-container .background-image {
  /* background-color: red; */
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
}

.mobile-message-preview-container .background-image img {
  width: 100%;
}

.mobile-message-preview-container .overlay {
  z-index: 10;

  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-self: stretch;

  justify-content: space-between;
  padding: 10%;
  overflow: hidden;
}
.mobile-message-preview-container .overlay img {
  width: 25%;
  min-height: 85px;
}

.mobile-message-preview-container .overlay .editable-values {
  width: 100%;
  height: 100%;
  display: flex;
  /* justify-content: center; */
  margin-top: auto;
  flex-direction: column;
  align-items: center;
  align-content: center;
  overflow: auto;
}

/* .overlay ::-webkit-scrollbar {
  display: none;
} */

.mobile-message-preview-container .overlay .action {
  padding-left: 5%;
  padding-right: 5%;
  width: 100%;
  padding-bottom: 20px;
}

.mobile-message-preview-container .overlay .header {
  height: 20px;
  padding-top: 10%;
  width: 100%;
}

.mobile-message-preview-container .overlay .header img {
  width: 100%;
}

.mobile-message-preview-container .overlay .action .button {
  background-color: #007aff;
  border-radius: 10px;
  height: 48px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;

  color: white;

  font-size: 16px;
  font-family: Inter-Regular;
  font-weight: 400;

  font-size: 1vw;
}

.mobile-message-preview-container .icon {
  width: 25%;
}

.mobile-message-preview-container .title {
  font-size: 28px;
  padding-top: 20px;
  font-family: Inter-SemiBold;
  font-weight: 400;
  text-align: center;

  font-size: 1.9vw;
  width: 100%;
  position: relative;
  overflow: hidden;
  height: 100%;
}

.mobile-message-preview-container .description {
  font-size: 18px;
  padding-top: 20px;
  font-family: Inter-Regular;
  font-weight: 400;
  text-align: center;

  font-size: 1vw;
  position: relative;
  overflow: hidden;
  height: 100%;
}

.mobile-message-preview-container-web {
  display: flex;
  margin-top: 24px;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: relative;
}

.mobile-message-preview-container-web .background-image {
  /* background-color: red; */
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.mobile-message-preview-container-web .background-image img {
  width: 100%;
}

.mobile-message-preview-container-web .overlay {
  /* background-color: red; */

  z-index: 10;

  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  /* align-self: stretch; */

  /* justify-content: space-between; */

  /* padding: 20%; */
  padding-left: 25%;
  padding-right: 25%;
  margin-top: 5px;

  aspect-ratio: 1.8 / 1;
}

.mobile-message-preview-container-web .overlay .editable-values {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  align-content: center;
}

.mobile-message-preview-container-web .overlay .action {
  padding-left: 5%;
  padding-right: 5%;
  width: 100%;
  padding-bottom: 20px;
}

.mobile-message-preview-container-web .overlay .header {
  height: 20px;
  /* background-color: red; */
  padding-top: 20%;
  width: 100%;
}

.mobile-message-preview-container-web .overlay .header img {
  width: 25%;
  margin-top: 10px;
  margin-left: 20px;
}

.mobile-message-preview-container-web .overlay .action .button {
  background-color: #007aff;
  border-radius: 10px;
  height: 48px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;

  color: white;

  font-size: 16px;
  font-family: Inter-Regular;
  font-weight: 400;

  font-size: 1vw;
}

.mobile-message-preview-container-web .icon {
  width: 10%;
}

.mobile-message-preview-container-web .title {
  padding-top: 10px;
  font-family: Inter-SemiBold;
  font-weight: 400;
  text-align: center;

  font-size: 0.9vw;
}

.mobile-message-preview-container-web .description {
  font-size: 18px;
  padding-top: 20px;
  font-family: Inter-Regular;
  font-weight: 400;
  text-align: center;

  font-size: 0.6vw;
}

/* Used BY*/
.usedBy-fold {
  padding: 45px 0px;
  z-index: 2;
}
.usedBy-title {
  color: #949faf;
  text-align: center;
  font-size: 20px;
  font-family: Inter-Regular;
  font-weight: 600;
  line-height: 170%;
  letter-spacing: 2px;
}

/* Loved by devs*/
.loved-by-dev-page {
  background: linear-gradient(
    113.61deg,
    #dce5ff 0.5%,
    rgba(231, 237, 255, 0.4) 100%
  );
  padding: 80px 0;
}
.loved-by-dev-post-title {
  color: #9aade8;
  text-align: center;
  font-size: 18px;
  font-family: Inter-Medium;
  font-weight: 600;
  line-height: 30px;
  letter-spacing: 2px;
}
.loved-by-dev-subtitle {
  color: rgba(22, 29, 72, 0.8);
  font-size: 17px;
  font-family: Inter-Regular;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: -0.17px;
  margin-top: 20px;
}
.loved-by-dev-title {
  color: #161d48;
  text-align: center;
  font-size: 44px;
  font-family: Inter-SemiBold;
  font-weight: 700;
  line-height: 57px;
  letter-spacing: -0.44px;
  margin-top: 15px;
}
@media (max-width: 960px) {
  .loved-by-dev-post-title {
    font-size: 16px;
    line-height: 27px;
  }
  .loved-by-dev-title {
    font-size: 32px;
    line-height: 35px;
  }
  .loved-by-dev-subtitle {
    font-size: 15px;
    line-height: 20px;
  }
}

/*review-card*/
.review-card {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  align-items: start;
  background-color: #ffffff99;
  border-radius: 20px;
  border: 0.8px solid #e4ebf6;
  box-shadow: 0px 0px 20px 0px #cfdbff;
  padding: 37px 27px;
  width: 306px;
}
.review-card-text-description {
  color: #424b59;
  font-size: 13px;
  font-family: Inter-Regular;
  line-height: 20px;
  text-align: left;
  margin: 0;
}
.review-card-icon {
  height: 42px;
  width: 42px;
}
.review-card-user-row {
  display: flex;
  align-items: start;
  column-gap: 15px;
}
.review-card-user-img {
  height: 42px;
}
.review-card-user-details {
  display: flex;
  flex-direction: column;
  justify-content: start;
  width: 100%;
}
.review-card-user-details h1 {
  font-family: Inter-Regular;
  font-size: 13px;
  font-weight: 600;
  line-height: 21px;
  text-align: left;
  margin: 0;
}
.review-card-user-details p {
  font-family: Inter-Regular;
  font-size: 11px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
  margin: 0;
}

/*See in action page*/
.see-in-action-page {
  background: linear-gradient(
    113.61deg,
    #dce5ff 0.5%,
    rgba(231, 237, 255, 0.4) 100%
  );
  position: relative;
  overflow: hidden;
  min-height: 677px;
}

.sia-decoration {
  position: absolute;
  width: 100%;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 2;
}

.see-in-action-container-small {
  width: 100%;
  height: 250px;
  margin-top: 25px;
  display: flex;
  justify-content: center;
}

.invisible-button {
  background: transparent;
  border: 0px;
  padding: 0;
  margin: 0;
}

#video {
  width: 1px;
  height: 1px;
  /* display: none; */
}

.see-in-action-image {
  height: 500px;
  display: flex;
  justify-content: center;
}

.see-in-action-image-small {
  height: 250px;
  display: flex;
  justify-content: center;
}

.see-in-action-container {
  /* background-color: red; */
  width: 100%;
  height: 500px;
  display: flex;
  justify-content: center;
  margin-top: -410px;
}
@media (max-width: 960px) {
  .see-in-action-page {
    min-height: 300px;
  }
}

/* Blog */

.blog-a {
  text-decoration: none !important;
}

.blog-title {
  margin-top: 150px;
  /* margin-bottom: 60px; */

  font-family: Inter-Bold;
  font-style: normal;
  font-weight: 700;
  font-size: 64px;
  line-height: 140%;
  /* or 90px */

  display: flex;
  align-items: left;
  letter-spacing: -0.02em;
  color: #161d48;
}

.blog-subtitle {
  margin-top: 20px;
  margin-bottom: 60px;

  font-family: Inter-Light;
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  /* line-height: 140%; */
  /* or 90px */

  display: flex;
  align-items: left;
  letter-spacing: -0.02em;
  color: #161d48;
  text-align: left;
}

.blog-post-image {
  margin-top: 40px;
  width: 100%;
}

.blog-post-title {
  margin-top: 20px;
  margin-left: 10px;

  font-family: Inter-SemiBold;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 160%;
  text-align: left;
  /* or 38px */

  display: flex;
  align-items: left;
  letter-spacing: -0.02em;

  color: #000000;
}

.blog-post-subtitle {
  margin-top: 10px;
  margin-left: 10px;

  font-family: Inter-Regular;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 160%;
  text-align: left;
  /* or 29px */

  letter-spacing: -0.02em;

  color: #000000;
  margin-bottom: 60px;
}

@media (max-width: 990px) {
  .blog-title {
    font-size: 40px;
    margin-top: 90px;
    margin-bottom: 40px;
  }

  .blog-subtitle {
    font-size: 20px;
  }

  .blog-post-image {
    margin-top: 20px;
  }

  .blog-post-subtitle {
    margin-bottom: 30px;
  }
}

/* Blog post page */
.blog-page {
  text-align: left;
}

.bold-text {
  font-family: Inter-SemiBold;
  font-weight: 800px;
}

.blog-page-title {
  font-family: Inter-Bold;
  font-style: normal;
  font-weight: 700;
  font-size: 54px;
  line-height: 150%;
  text-align: left;
  /* or 81px */

  display: flex;
  align-items: left;
  letter-spacing: -0.02em;

  color: #000000;

  margin-top: 70px;
  margin-bottom: 30px;
}

.blog-image {
  width: 100%;
}

.blog-page-post-image {
  margin-top: 140px;
  width: 100%;
}

@media (max-width: 990px) {
  .blog-page-post-image {
    margin-top: 90px;
  }

  .blog-page-title {
    font-size: 30px;
    margin-top: 40px;
  }
}

.blog-page-reading-time {
  margin-bottom: 60px;

  font-family: Inter-Light;
  font-style: normal;
  font-weight: 200;
  font-size: 16px;
  color: #4f4c4c;
  text-align: left;
}

.blog-page-text p {
  /* background-color: red; */
  font-family: Inter-Regular;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 150%;
  text-align: left;
  margin-bottom: 40px;
}

.blog-page-text a {
  /* background-color: red; */
  text-decoration: none;
  color: #5064ff;
}

.blog-page-text h2 {
  font-family: Inter-Bold;
  font-style: normal;
  font-weight: 800;
  font-size: 30px;
  line-height: 150%;
  margin-top: 80px;
  margin-bottom: 60px;
  text-align: left;
}

.blog-page-text h3 {
  font-family: Inter-SemiBold;
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 150%;
  margin-top: 80px;
  margin-bottom: 40px;
  text-align: left;
}

.blog-page-text .important-p {
  color: rgba(18, 72, 168, 0.72);
  font-family: Inter-Medium;
  padding: 30px;
  border-radius: 20px;
  border: 1px solid var(--grey-200, #e4ebf6);
  box-shadow: 0px 0px 20px 0px rgba(223, 229, 243, 0.5);
}

.blog-page-text li {
  font-family: Inter-Regular;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 150%;
  margin-top: 30px;
  text-align: left;
}

.blog-page-text b {
  font-family: Inter-Medium;
}

.blog-page-read-more-articles {
  padding-top: 100px;
  text-align-last: left;

  font-family: Inter-Light;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 150%;
  margin-top: 30px;
}

@media (max-width: 990px) {
  .blog-page-text h2 {
    margin-top: 60px;
    margin-bottom: 30px;
  }

  .blog-page-read-more-articles {
    padding-top: 70px;
  }
}

.blog-free-trial-desktop {
  width: 100%;
  margin-top: 60px;
}

/* Others */
.display-on-small {
  display: none;
}
.display-on-big {
  display: block;
}

.display-on-big-flex {
  display: flex;
}

.display-on-small-flex {
  display: none;
}

@media (max-width: 960px) {
  .display-on-small {
    display: block;
  }

  .display-on-small-flex {
    display: flex;
  }

  .display-on-big {
    display: none;
  }

  .display-on-big-flex {
    display: none;
  }
}

/* Margins  */

.no-margins-div {
  margin-top: 0px !important;
  padding-top: 0px !important;
  width: 100%;
  height: 100%;
  position: absolute;
}
.carousel-root {
  width: 100%;
}
/*CAROUSEL PRICING*/
.pricing-carousel .carousel .slider-wrapper {
  min-height: 700px;
}
/* CAROUSEL LovedBy*/
.carousel-500 .carousel .slider-wrapper {
  min-height: 500px;
}
.slide {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.carousel .control-dots .dot {
  background: #5064ff;
  box-shadow: none;
}

.mt-20 {
  margin-top: 20px;
}

.mt-40 {
  margin-top: 40px;
}

.mb-20 {
  margin-bottom: 20px !important;
}

.mb-60 {
  margin-bottom: 60px !important;
}

.mt-24 {
  margin-top: 24px !important;
}

.mb-24 {
  margin-bottom: 24px !important;
}

.mt-16 {
  margin-top: 16px !important;
}

.mb-16 {
  margin-bottom: 16px !important;
}

.mt-8 {
  margin-top: 8px;
}

.mt-60 {
  margin-top: 60px;
}

.mt-36 {
  margin-top: 36px;
}

.mt-48 {
  margin-top: 48px;
}

.mt-32 {
  margin-top: 32px;
}

.mb-24 {
  margin-bottom: 24px;
}

.pt-25 {
  padding-top: 25px;
}

.pt-60 {
  padding-top: 60px;
}

.pb-60 {
  padding-bottom: 60px;
}

.pb-80 {
  padding-bottom: 80px;
}

.pl-32 {
  padding-left: 32px;
}

.pr-32 {
  padding-right: 32px;
}

.ml-32 {
  margin-left: 32px;
}

.ml-10 {
  margin-left: 10px;
}

.ml-12 {
  margin-left: 12px;
}

.mt-42 {
  margin-top: 42px;
}

.z-index-2 {
  z-index: 3 !important;
}

.wh-20 {
  width: 20px !important;
  height: 20px !important;
}

.wh-100 {
  width: 100px !important;
  height: 100px !important;
}

.red {
  background-color: red;
}
.text-overflow {
  position: relative;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: break-spaces;
  max-width: 100%;
}
/* Tosty  */

:root {
  --toastify-color-light: #fff;
  --toastify-color-dark: #121212;
  --toastify-color-info: #3498db;
  --toastify-color-success: #5064ff;
  --toastify-color-warning: #f1c40f;
  --toastify-color-error: #ff6f78;
  --toastify-color-transparent: rgba(255, 255, 255, 0.7);

  --toastify-icon-color-info: var(--toastify-color-info);
  --toastify-icon-color-success: var(--toastify-color-success);
  --toastify-icon-color-warning: var(--toastify-color-warning);
  --toastify-icon-color-error: var(--toastify-color-error);

  --toastify-toast-width: 320px;
  --toastify-toast-background: #fff;
  --toastify-toast-min-height: 64px;
  --toastify-toast-max-height: 800px;
  --toastify-font-family: "Inter-Medium";
  --toastify-z-index: 9999;

  --toastify-text-color-light: #161d48;
  --toastify-text-color-dark: #fff;
}

body {
  --cc-bg: #f9faff;
  --cc-text: #112954;
  --cc-btn-primary-bg: #5064ff;
  --cc-btn-primary-text: var(--cc-bg);
  --cc-btn-primary-hover-bg: #1d2e38;
  --cc-btn-secondary-bg: #dfe7f9;
  --cc-btn-secondary-text: var(--cc-text);
  --cc-btn-secondary-hover-bg: #c6d1ea;
  --cc-toggle-bg-off: #8fa8d6;
  --cc-toggle-bg-on: #5064ff;
  --cc-toggle-bg-readonly: #cbd8f1;
  --cc-toggle-knob-bg: #fff;
  --cc-toggle-knob-icon-color: #ecf2fa;
  --cc-block-text: var(--cc-text);
  --cc-cookie-category-block-bg: #ebeff9;
  --cc-cookie-category-block-bg-hover: #dbe5f9;
  --cc-section-border: #f1f3f5;
  --cc-cookie-table-border: #e1e7f3;
  --cc-overlay-bg: rgba(230, 235, 255, 0.85);
  --cc-webkit-scrollbar-bg: #ebeff9;
  --cc-webkit-scrollbar-bg-hover: #3859d0;
}

#cs .title {
  font-style: normal;
  font-weight: 600;
  font-family: Inter-SemiBold;

  letter-spacing: -0.01em;
}

.c-bl .title {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  font-family: Inter-Medium;

  letter-spacing: -0.01em;
}
